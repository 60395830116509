<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <!-- <v-btn type="button" elevation="0" color="primary">Salvar</v-btn> -->
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            to="/divergencias">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row v-if="dataset.data.status == 0">
            <v-col>
                <v-banner single-line outlined>
                    <v-icon slot="icon" color="warning" size="36">
                        mdi-alert-outline
                    </v-icon>


                    Esta {{ dataset.data.user.uuid_ref ? 'divergência' : 'habilitação' }} ainda não foi analisada

                </v-banner>
            </v-col>
        </v-row>

        <v-row v-if="dataset.data.status !== 0">
            <v-col>
                <v-banner single-line outlined>

                    <v-icon slot="icon"
                        :color="dataset.data.status === 1 ? 'accent' : (dataset.data.status === 3 ? '#33691E' : 'error')"
                        size="36">
                        {{ (dataset.data.status == 1 || dataset.data.status == 3) ? 'mdi-check-circle-outline' :
                            'mdi-close-circle-outline' }}
                    </v-icon>

                    <div class="d-flex justify-space-between align-center">
                        <div>
                            Esta divergência foi marcada como <span class="text-uppercase"> <strong>{{
                                dataset.data.status === 1
                                ? 'aprovada'
                                : (dataset.data.status === 2
                                    ? 'rejeitada'
                                    : (dataset.data.status === 3
                                        ? 'aprovada parcialmente'
                                        : ''
                                    )
                                )
                            }}</strong>

                            </span> por
                            <span class="text-uppercase"> <strong>{{ dataset.data.admin ? dataset.data.admin.name : 'N/A'
                            }}</strong></span>

                        </div>

                        <div class="icon-container">
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-icon icon v-on="on" @click="statusReset()" size="large" class="me-2 border ">
                                        mdi-reload
                                    </v-icon>
                                </template>
                                <span>Restaurar status credor</span>
                            </v-tooltip>
                        </div>
                    </div>
                </v-banner>
            </v-col>
        </v-row>

        <v-form ref="form" v-model="dataForm.validForm" v-if="dataset.data && dataset.data.user">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes credor</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense disabled id="name" label="Nome completo"
                                                v-model="dataset.data.user.name" required :rules="[$rules.required]"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense disabled label="E-mail" v-model="dataset.data.user.email"
                                                required outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense id="uuid" label="Código de referência"
                                                v-model="dataset.data.user.uuid_ref" outlined hide-details="auto" disabled>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <v-row v-if="dataset.data.status == 0">
            <v-col cols="12">
                <v-card class="card-form-outlined" color="gray">
                    <v-card-title class="card-group-title">
                        <div class="title-container">
                            <div class="title-block">
                                <span id="title" class="table-card-title">Ações</span>
                            </div>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-btn type="button" elevation="0" color="accent" class="mr-5"
                                    @click="updateStatus('approve')">Aprovar</v-btn>

                                <v-btn type="button" elevation="0" color="indigo darken-1" class="mr-5 white--text"
                                    @click="updateStatus('partial')">Aprovar Parcialmente</v-btn>

                                <v-btn type="button" elevation="0" color="error"
                                    @click="updateStatus('reject')">Rejeitar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12">
                        <v-card class="card-form-outlined" color="gray">
                            <v-card-title class="card-group-title">
                                <div class="title-container">
                                    <div class="title-block">
                                        <span id="title" class="table-card-title">Contraditórios</span>
                                    </div>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field dense type="date" label="Prazo contraditório"
                                            :value="formatDate(dataset.data.contradictory_deadline_at)"
                                            @input="updateContraditory" outlined hide-details="auto">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn :loading="dataset.loading" type="button" elevation="0" color="primary"
                                            @click="updateContradictoryDeadline()">Salvar</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-text>
                                <v-divider></v-divider>
                            </v-card-text>
                            <v-card-text>
                                <span v-if="!dataset.data.contradictory_reason">Nenhum contraditório encontrado</span>
                                <div v-else v-html="dataset.data.contradictory_reason"></div>
                            </v-card-text>
                            <v-card-text v-if="dataset.data.contradictory_reason_file_url">
                                <v-btn type="button" elevation="0" color="primary" outlined
                                    :href="dataset.data.contradictory_reason_file_url" target="_blank">Visualizar
                                    documento
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card class="card-form-outlined" color="gray">
                    <v-card-title class="card-group-title">
                        <div class="title-container">
                            <div class="title-block">
                                <span id="title" class="table-card-title">Negócios</span>
                            </div>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <strong>Habilitação/Divergência</strong>
                                        <br />
                                        <strong>Devedora: </strong> <span>{{ dataset.data.client ? dataset.data.client.name
                                            : '' }}</span>
                                        <br />
                                        <strong>Solicitado em: </strong>
                                        <span>{{
                                            dataset.data.created_at | defaultDate('DD/MM/YYYY HH:mm:ss')
                                        }}</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-expansion-panels>
                                            <v-expansion-panel v-for="(eventItem, eventIndex) in dataset.data.items"
                                                :key="eventIndex">
                                                <v-expansion-panel-header>
                                                    <div class="d-flex align-center">
                                                        <strong>Origem: </strong>
                                                        <span>
                                                            {{
                                                                truncateText(
                                                                    eventItem.origin || 'N/A',
                                                                    30
                                                                )
                                                            }}
                                                        </span>
                                                        /
                                                        <strong>Valor: </strong>
                                                        <span v-if="eventItem.amount">
                                                            {{
                                                                eventItem.currency | enum(
                                                                    dataset.currencyTypes,
                                                                    'id',
                                                                    'currencyFormat'
                                                                )
                                                            }}
                                                            {{ eventItem.amount | currency }}
                                                        </span>
                                                        <span v-else>N/A</span>

                                                        <v-btn v-if="dataset.data.status === 0"
                                                            @click.stop="partialAcceptModal(eventItem)"
                                                            class="ml-auto mr-10" elevation="0" color="primary"
                                                            outlined>Editar valor
                                                        </v-btn>

                                                    </div>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-row>
                                                        <v-col>
                                                            <p>
                                                                <span class="section-info-title">Devedora</span>
                                                                <br />
                                                                <span class="section-info-value text-uppercase">
                                                                    {{ dataset.data.client.name }}
                                                                </span>
                                                            </p>
                                                            <p>
                                                                <span class="section-info-title">Origem</span>
                                                                <br />
                                                                <span class="section-info-value text-uppercase">
                                                                    {{ eventItem.origin }}</span>
                                                            </p>
                                                            <p>
                                                                <span class="section-info-title">Moeda</span>
                                                                <br />
                                                                <span class="section-info-value text-uppercase">
                                                                    {{
                                                                        eventItem.currency
                                                                        | enum(
                                                                            dataset.currencyTypes,
                                                                            'id',
                                                                            'name'
                                                                        )
                                                                    }}
                                                                </span>
                                                            </p>
                                                            <p>
                                                                <span class="section-info-title">Valor</span>
                                                                <br />
                                                                <span class="section-info-value text-uppercase">{{
                                                                    eventItem.currency
                                                                    | enum(
                                                                        dataset.currencyTypes,
                                                                        'id',
                                                                        'currencyFormat'
                                                                    )
                                                                }}
                                                                    {{
                                                                        eventItem.amount | currency
                                                                    }}</span>
                                                            </p>

                                                            <p>
                                                                <span class="section-info-title">Valor parcial aceito</span>
                                                                <br />
                                                                <span class="section-info-value text-uppercase">{{
                                                                    eventItem.currency
                                                                    | enum(
                                                                        dataset.currencyTypes,
                                                                        'id',
                                                                        'currencyFormat'
                                                                    )
                                                                }}
                                                                    {{
                                                                        eventItem.partial_amount | currency
                                                                    }}</span>
                                                            </p>

                                                            <v-row>
                                                                <v-col cols="6">
                                                                    <span class="section-info-title">Classe</span>
                                                                    <v-autocomplete
                                                                        dense
                                                                        :items="dataset.creditorTypes"
                                                                        v-model="eventItem.type"
                                                                        item-value="id"
                                                                        item-text="name"
                                                                        outlined
                                                                        hide-details="auto"
                                                                        required
                                                                        :rules="[$rules.required]"
                                                                        no-data-text="Nenhuma classe encontrada"
                                                                        @change="onAutocompleteChange(eventIndex)"
                                                                    >
                                                                    </v-autocomplete>
                                                                </v-col>
                                                                <v-col cols="auto" v-if="eventItem.isChanged">
                                                                    <v-btn class="mt-6" @click="() => updateClassType(eventItem, eventIndex)" dense color="primary">
                                                                        Salvar
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                       
                                                            <br />
                                                            <p>
                                                                <span class="section-info-title">Observações</span>
                                                                <br />
                                                                <span class="section-info-value text-uppercase">
                                                                    {{ eventItem.observation }}</span>
                                                            </p>
                                                            <p>
                                                                <span class="section-info-title">Documentos</span>
                                                                <br />
                                                                <span class="section-info-value">{{
                                                                    eventItem.docs.length
                                                                }}
                                                                    arquivo(s)</span>
                                                            </p>
                                                            <!-- <v-row>
                                                                <v-col v-for="(docItem, docIndex ) in eventItem.docs" :key="docIndex">
                                                                    <v-img @click="openDoc(docItem.doc.file_url)"
                                                                        class="rounded-xl"
                                                                        lazy-src="https://picsum.photos/id/11/10/6"
                                                                        :height="100" :width="100"
                                                                        :src="docItem.doc.file_url"></v-img>
                                                                </v-col>
                                                            </v-row> -->
                                                            <v-divider v-if="eventItem.docs.length > 0"></v-divider>
                                                            <br>
                                                            <v-row v-for="category in getEventItemsByCategory(eventItem)"
                                                                :key="category.id">
                                                                <v-col cols="12">
                                                                    <h3>{{ category.name }}</h3>
                                                                    <br>
                                                                    <v-row>
                                                                        <v-col v-for="doc in category.docs" :key="doc.id"
                                                                            cols="auto">
                                                                            <v-img @click="openDoc(doc.file_url)"
                                                                                class="rounded-xl"
                                                                                lazy-src="https://picsum.photos/id/11/10/6"
                                                                                :height="100" :width="100"
                                                                                :src="doc.file_url"></v-img>
                                                                            {{ doc.name }}
                                                                            <br>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-divider></v-divider>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12">
                        <v-card class="card-form-outlined" color="gray">
                            <v-card-title class="card-group-title">
                                <div class="title-container">
                                    <div class="title-block">
                                        <span id="title" class="table-card-title">Documento</span>
                                    </div>
                                </div>
                            </v-card-title>

                            <v-col cols="12">
                                <v-file-input dense accept="image/png, image/jpeg, application/pdf" prepend-icon="" outlined
                                    counter hint="O arquivo não pode ser superior a 15MB" hide-details="auto"
                                    label="Documento" :clearable="true" @change="onFileSelected($event)" show-size
                                    truncate-length="50" ref="file">
                                </v-file-input>

                                <template v-if="dataset.data.reason_document_url">
                                    <v-row class="mt-n5">
                                        <v-col cols="12">
                                            <v-btn type="button" elevation="0" color="primary" outlined
                                                :href="dataset.data.reason_document_url" target="_blank">Visualizar
                                                documento
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>

                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12">
                        <v-card class="card-form-outlined" color="gray">
                            <v-card-title class="card-group-title">
                                <div class="title-container">
                                    <div class="title-block">
                                        <span id="title" class="table-card-title">Motivo</span>
                                        <br>
                                        <span class="text-subtitle-2 text-none font-weight-medium">Motivo para aprovação ou
                                            rejeição</span>
                                    </div>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="font-weight-bold">Texto curto</p>
                                        <tiptap-vuetify class="custom-editor-short" :disabled="dataset.data.status !== 0"
                                            v-model="dataset.data.smallReason" :rules="[$rules.required]"
                                            :extensions="extensions" hide-details="auto" />
                                        <!-- <v-btn v-if="dataset.data.status === 0" type="button" elevation="0" color="primary"
                                            outlined class="mt-5" @click="openDialogDynamicText(true);">Adicionar
                                            texto</v-btn>-->
                                        <v-tooltip bottom>
                                            <template #activator="{ on }">
                                                <v-icon v-on="on" v-show="dataset.data.status == 0"
                                                    @click="openDialogDynamicText(true)" class="me-2">
                                                    mdi-text-short
                                                </v-icon>
                                            </template>
                                            <span>Selecionar textos curtos predefinidos</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="font-weight-bold">Texto longo</p>
                                        <tiptap-vuetify class="custom-editor-long" :disabled="dataset.data.status !== 0"
                                            v-model="dataset.data.reason" :rules="[$rules.required]"
                                            :extensions="extensions" hide-details="auto" />
                                        <!-- <v-btn v-if="dataset.data.status === 0" type="button" elevation="0" color="primary"
                                            outlined class="mt-5" @click="openDialogDynamicText(false);">Adicionar
                                            texto</v-btn>-->
                                        <v-tooltip bottom>
                                            <template #activator="{ on }">
                                                <v-icon v-on="on" v-show="dataset.data.status == 0"
                                                    @click="openDialogDynamicText(false)" class="me-2">
                                                    mdi-text-long
                                                </v-icon>
                                            </template>
                                            <span>Selecionar textos longos predefinidos</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogDynamicText" :width="900">
            <v-card :max-height="700">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="font-weight-bold mt-6 title-page">Textos Automáticos</div>
                            <p class="mt-2">
                                Selecione um texto abaixo para adicionar no motivo
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div>
                                <v-list-item two-line v-for="(item, index) in dataset.dynamicTexts" :key="index"
                                    style="cursor: pointer" @click="selectDynamicText(item)">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold">{{ item.name }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <div v-html="dynamicTextSmallType ? item.small_description : item.description">
                                            </div>
                                            <v-divider></v-divider>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <!-- <v-card class="rounded-lg mb-10" v-for="(item, index) in dataset.dynamicTexts" :key="index"
                                elevation="2">
                                <v-card-title>
                                    {{ item.name }}
                                </v-card-title>
                                <v-card-text>
                                    <div v-html="dynamicTextSmallType ? item.small_description : item.description"></div>
                                </v-card-text>
                            </v-card> -->
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-btn type="button" elevation="0" color="error"
                                @click="dialogDynamicText = false;">Cancelar</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="dialogPartialAccept" :width="700">
            <v-card :max-height="500" v-if="dataset.selectedItem">
                <v-card-text>x
                    <v-row>
                        <v-col>
                            <div class="font-weight-bold mt-6 title-page">Aceitar parcialmente</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <p>
                                <span class="section-info-title ">Valor solicitado credor</span>
                                <br />
                                <span class="text-uppercase text-h6">{{
                                    dataset.selectedItem.currency
                                    | enum(
                                        dataset.currencyTypes,
                                        'id',
                                        'currencyFormat'
                                    )
                                }}
                                    {{ dataset.selectedItem.amount | currency }}
                                </span>
                            </p>

                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6">
                            <span class="section-info-title">Digite valor aceito</span>
                            <br />
                            <br />
                            <v-currency-field label="Valor" dense v-model="dataset.selectedItem.partialAmount"
                                :allowNegative=false hide-details="auto" outlined class="mt-n3">
                            </v-currency-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                    <div class="mr-10 ml-2">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" @click="savePartialValue()" color="primary" dark elevation="2"
                                    :loading="dataset.loading">
                                    OK
                                </v-btn>
                            </template>
                            <span>Salvar valor parcial</span>
                        </v-tooltip>
                    </div>
                    <div>
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" color="grey" text
                                    @click.native="dialogPartialAccept = false">Cancelar</v-btn>
                            </template>
                            <span>Fechar</span>
                        </v-tooltip>
                    </div>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogViewDoc" max-width="60%" @keydown.esc="cancel">
            <v-card>
                <v-img :src="selectedDoc" alt="" contain />
            </v-card>
        </v-dialog>





    </v-container>
</template>

<script>
import states from "@/common/data/states.data"
import string from "@/common/util/string"

import userTypes from "@/common/data/user-types.data"
import currencyTypes from "@/common/data/currency-types.data"

import documentTypes from "@/common/data/document-types.data"
import axios from "axios"
import creditorTypes from "@/common/data/creditor-types.data"

//import TiptapResizeImage from '@tiptap/extension-resize-image'; nao complica
// @tiptap/extension-image-resize -> Nao instala com NPM

//import Image from 'tiptap-extension-image-freely' // erro ao compilar

//import TiptapResizeImage from '@tiptap/extension-image'


import {
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History, Table, TableCell, TableHeader,
    TableRow
} from 'tiptap-vuetify'


export default {
    name: "UserDetails",

    components: { 'tiptap-vuetify': TiptapVuetify },

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        documentTypes: documentTypes,
        dataset: {
            userTypes: userTypes,
            currencyTypes: currencyTypes,
            breadcrumbItems: [],
            states: states,
            selectedItem: null,
            loading: false,
            creditorTypes: creditorTypes,
            data: {
                smallReason: "",
                reason: "",
                partialAmount: null,
                reasonDocument: null,
            },
            partialItems: [],
            dynamicTexts: [],
            events: [],
        },
        dialogDynamicText: false,
        dialogPartialAccept: false,
        dynamicTextSmallType: false,
        selectedDoc: null,
        dialogViewDoc: false,
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            showPassword: false,
            showPasswordConfirmation: false,
        },

        extensions: [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            // TiptapResizeImage,//.configure({
            // Configurações opcionais
            // handleClass: 'custom-resize-handle', // Classe CSS para os manipuladores de redimensionamento
            //imageClassName: 'custom-resizable-image', // Classe CSS para imagens redimensionáveis
            //imageStyle: 'width: 100%; height: auto;', // Estilos CSS para imagens redimensionáveis
            // }),


            ListItem,
            BulletList,
            OrderedList,
            [Heading, {
                options: {
                    levels: [1, 2, 3]
                }
            }],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak,
            [Table, {
                options: {
                    resizable: true
                }
            }],
            TableCell,
            TableHeader,
            TableRow,
        ],
        content: [
            {
                type: 'paragraph',
                content: [
                    {
                        type: 'text',
                        text: 'Exemplo de texto com uma imagem:'
                    },
                    {
                        type: 'image',
                        attrs: {
                            src: 'https://via.placeholder.com/150',
                            alt: 'Imagem de exemplo',
                            style: 'width: 150px; height: 150px;' // Estilo para definir largura e altura da imagem
                        }
                    }
                ]
            }
        ],
    }),

    created() {
        this.loadData()
    },

    methods: {
        goBack() {
            this.$router.replace("/divergencias");
        },

        openDialogDynamicText(small = false) {
            this.loadDynamicTexts()

            this.dynamicTextSmallType = small;
            this.dialogDynamicText = true;

        },

        onFileSelected(file) {

            this.dataset.data.reasonDocument = file;
        },


        getEventItemsByCategory(eventItem) {
            const categories = {};

            eventItem.docs.forEach(docItem => {
                const category_id = docItem.doc.type;

                if (!categories[category_id]) {
                    categories[category_id] = {
                        id: category_id,
                        name: this.getCategoryName(category_id),
                        docs: []
                    };
                }

                categories[category_id].docs.push({
                    id: docItem.id,
                    name: docItem.doc.name,
                    file_url: docItem.doc.file_url
                });
            });

            return Object.values(categories);
        },

        getCategoryName(category_id) {
            return this.documentTypes.find(category => category.id === category_id).name;
        },

        openDoc(doc) {
            window.open(doc, '_blank');
            // this.selectedDoc = doc;
            // this.dialogViewDoc = true;
        },

        truncateText(text, maxLength) {
            return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
        },

        updateContraditory(value) {
            this.dataset.data.contradictory_deadline_at = value;
        },

        formatDate(date) {
            if (!date || date === "0000-00-00 00:00:00") return null;
            return this.$moment(date).format('YYYY-MM-DD');
        },

        getMessageByTypeEvent(item) {
            let result = '';

            switch (item.type) {
                case 'login-account':
                    result = `Usuário acessou o sistema no dia ${this.$moment(item.created_at).format('DD/MM/YYYY HH:mm')}`;
                    break;

                case 'accept-value':
                    result = `Usuário aceitou o crédito no valor de R$ ${this.dataset.data.requested_value} no dia ${this.$moment(item.created_at).format('DD/MM/YYYY HH:mm')}`;
                    break;

                case 'verify-account':
                    result = `Usuário verificou a conta no dia ${this.$moment(item.created_at).format('DD/MM/YYYY HH:mm')}`;
                    break;

                case 'register-user':
                    result = `Usuário criou a conta no dia ${this.$moment(item.created_at).format('DD/MM/YYYY HH:mm')}`;
                    break;

                case 'create-event':
                    result = `Usuário registrou uma divergência no dia ${this.$moment(item.created_at).format('DD/MM/YYYY HH:mm')}`;
                    break;

                default:
                    break;
            }

            return result;
        },

        getTitleByTypeEvent(item) {
            let result = '';

            switch (item.type) {
                case 'login-account':
                    result = `acesso`;
                    break;

                case 'accept-value':
                    result = `CRÉDITO`;
                    break;

                case 'verify-account':
                    result = `verificação`;
                    break;

                case 'register-user':
                    result = `cadastro`;
                    break;

                case 'create-event':
                    result = `divergência`;
                    break;

                default:
                    break;
            }

            return result;
        },

        async loadData() {
            await this.load();

            this.setPage();
            this.listBreadCrumbItems();
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = `Detalhes da ${this.dataset.data.user.uuid_ref ? 'divergência' : 'habilitação'}`;
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Divergência",
                disabled: false,
                to: "/divergencias",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: `Detalhes da ${this.dataset.data.user.uuid_ref ? 'divergência' : 'habilitação'}`,
                disabled: true,
                exact: true,
            });
        },

        selectDynamicText(item) {
            if (this.dynamicTextSmallType) {
                this.dataset.data.smallReason += item.small_description
            } else {
                this.dataset.data.reason += item.description
            }

            this.dialogDynamicText = false;
        },

        async updateStatus(approve = 'reject') {

            if (approve === 'partial' && (!this.dataset.partialItems.length)) {
                this.$dialog.notify.error("Informe os novos valores para continuar!", { position: "top-right", timeout: 2000 });
                return;
            }

            if (!this.dataset.data.smallReason || !this.dataset.data.reason) {
                this.$dialog.notify.error("Motivo é obrigatório", { position: "top-right", timeout: 2000 });
                return;
            }

            const proceedAction = await this.$dialog.confirm({
                text: `Deseja ${approve === 'approve' ? 'aprovar' : (approve === 'reject' ? 'rejeitar' : 'aprovar parcialmente')} a divergência?`,
                title: `${approve === 'approve' ? 'Aprovar' : (approve === 'reject' ? 'Rejeitar' : 'Aprovar parcialmente')} divergência`,
                actions: {
                    false: 'Cancelar',
                    true: approve === 'approve' ? 'Aprovar' : (approve === 'reject' ? 'Rejeitar' : 'Aprovar parcialmente')
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            // const data = {
            //     smallReason: this.dataset.data.smallReason,
            //     reason: this.dataset.data.reason,
            //     reasonDocument: this.dataset.data.reasonDocument,
            //     status: approve === 'approve' ? 1 : (approve === 'reject' ? 2 : 3)
            // }

            // if (approve === 'partial') {
            //     data.eventItems = this.dataset.partialItems;
            // }

            const formData = new FormData();
            formData.append('smallReason', this.dataset.data.smallReason);
            formData.append('reason', this.dataset.data.reason);
            formData.append('status', approve === 'approve' ? 1 : (approve === 'reject' ? 2 : 3));

            if (this.dataset.data.reasonDocument) {
                formData.append('reasonDocument', this.dataset.data.reasonDocument);
            }

            if (approve === 'partial') {
                formData.append('eventItems', JSON.stringify(this.dataset.partialItems));
            }

            try {
                const response = await axios.patch(`/api/admin/user-events/action/${this.$route.params.id}`, formData)

                this.$dialog.notify.info(`Divergência ${approve === 'approve' ? 'aprovada' :
                    (approve === 'reject' ? 'rejeitada' : 'aprovada parcialmente')} com sucesso!`, { position: "top-right", timeout: 2000 });

                this.goBack();

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        async updateContradictoryDeadline() {

            if (!this.dataset.data.contradictory_deadline_at) {
                this.$dialog.notify.error("O campo Prazo é obrigatório", { position: "top-right", timeout: 2000 });
                return;
            }

            const proceedAction = await this.$dialog.confirm({
                text: `Deseja atualizar o prazo para contraditórios?`,
                title: `Atualizar divergência`,
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataset.loading = true;

            try {
                const response = await axios.patch(`/api/admin/user-events/contradictory-deadline/${this.$route.params.id}`, { contradictory_deadline_at: this.dataset.data.contradictory_deadline_at })

                this.$dialog.notify.info(`Divergência atualizada com sucesso!`, { position: "top-right", timeout: 2000 });

                this.goBack();

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },

        async load() {

            try {
                const result = await axios.get(`/api/admin/user-events/find/${this.$route.params.id}`);

                const data = result.data.content;

                this.dataset.data = data;

                this.dataset.data = {
                    ...data,
                    items: data.items.map(item => ({
                        ...item,
                        isChanged: false, 
                    })),
                };

                this.dataset.data.smallReason = data.small_reason || ""
                this.dataset.data.reason = data.reason || ""

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async loadDynamicTexts() {

            try {
                const result = await axios.get(`/api/admin/dynamic-texts`, { params: { start: 1, limit: 100000, search: {} } });

                this.dataset.dynamicTexts = result.data.content.data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        buildPayload() {
            const data = {
                name: this.dataset.data.name,
                email: this.dataset.data.email,
                description: this.dataset.data.description,
                client: this.dataset.data.client,
                unit: this.dataset.data.unit,
                associations: this.normalizeAssociations(),
                credential: {
                    username: this.dataset.data.email,
                    password: this.dataset.data.password,
                },
                roles: this.dataset.data.roles

            }

            return data;
        },

        async statusReset(id) {

            const proceedAction = await this.$dialog.confirm({
                text: 'Tem certeza que deseja restaurar status do credor?',
                title: 'Restaurar status credor',
                actions: {
                    false: 'Cancelar',
                    true: 'Restaurar'
                }
            })

            if (!proceedAction) return;

            try {
                const response = await axios.patch(`/api/admin/user-events/action/${this.$route.params.id}`, { status: 0 });

                this.$dialog.notify.info('Status do credor restaurado com sucesso!', { position: "top-right", timeout: 2000 });

                this.goBack()

            } catch (error) {
                console.log("erro: ", error)
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        partialAcceptModal(item) {
            this.dataset.selectedItem = item;
            this.dialogPartialAccept = true;
        },

        savePartialValue() {

            if (!this.dataset.selectedItem.partialAmount) {

                this.$dialog.notify.error('Valor digitado é inválido!', { position: "top-right", timeout: 2000 });

                return;
            }

            const index = this.dataset.partialItems.findIndex((x) => x.id == this.dataset.selectedItem.id);

            if (index == -1) {
                this.dataset.partialItems.push({ id: this.dataset.selectedItem.id, amount: this.dataset.selectedItem.partialAmount })
            } else {
                this.dataset.partialItems[index] = { id: this.dataset.selectedItem.id, amount: this.dataset.selectedItem.partialAmount };
            }

            this.dialogPartialAccept = false;

        },

        
        onAutocompleteChange(eventIndex) {
            this.$set(this.dataset.data.items[eventIndex], 'isChanged', true);
        },


        async updateClassType(eventItem, eventIndex) {

            const proceedAction = await this.$dialog.confirm({
                text: `Deseja atualizar a classe do negócio?`,
                title: `Atualizar classe`,
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            const id = eventItem.id
            const type = eventItem.type

            this.dataset.loading = true;

            try {
                const response = await axios.patch(`/api/admin/user-events/class-type-change/${id}`, { type: type })

                this.$dialog.notify.info(`Classe atualizada com sucesso!`, { position: "top-right", timeout: 3000 });

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 3000
                })
            } finally {
                this.dataset.loading = false;
            }
            
            this.$set(this.dataset.data.items[eventIndex], 'isChanged', false);
        },
    },

};

</script>
  
<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}

.custom-editor-short {
    .tiptap-vuetify-editor__content {
        min-height: 100px !important;
    }

    .ProseMirror {
        min-height: 100px !important;
    }
}

.custom-editor-long {
    .tiptap-vuetify-editor__content {
        min-height: 200px !important;
    }

    .ProseMirror {
        min-height: 200px !important;
    }
}

.icon-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
}

.image-resizer {
    display: inline-flex;
    position: relative;
    flex-grow: 0;
}

.image-resizer .resize-trigger {
    position: absolute;
    right: -6px;
    bottom: -9px;
    opacity: 0;
    transition: opacity .3s ease;
    color: #3259a5;
}

.image-resizer:hover .resize-trigger {
    opacity: 1;
}
</style>